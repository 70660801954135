<template>
  <a-modal
    :title="'储值卡' + model.card_no + '消费记录'"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @cancel="() => { $emit('cancel') }"
  >
    <div slot="footer">
      <a-button @click="() =>{ $emit('cancel') }">取消</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_render" slot-scope="text" style="text-align: left; min-width: 90px; max-width: 240px">
        {{ (text / 100).toFixed(2) }}
      </div>
    </s-table>
  </a-modal>
</template>
<script>

import { valueCardConsumeRecordList } from '@/api/shop_value_card'
import { STable } from '@/components'
export default {
  name: 'ValueCardConsumeRecordList',
  components: {
    STable
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          ellipsis: true,
          dataIndex: 'order_no'
        },
        {
          title: '金额',
          ellipsis: true,
          dataIndex: 'value',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '备注',
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '时间',
          ellipsis: true,
          dataIndex: 'create_time'
        }
      ],
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return valueCardConsumeRecordList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.queryParam.card_no = this.model.card_no
  }
}
</script>
<style>
</style>
