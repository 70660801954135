<template>
  <a-modal
    title="状态"
    :width="480"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select v-decorator="['status', {rules: [{ required: true, message: '请选择状态' }]}]" allow-clear placeholder="请选择">
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.value_card_status">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'

const fields = ['batch']
export default {
  name: 'ExportValueCardForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadEditInfo(this.model)
      }
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
    }
  }
}
</script>
