<template>
  <a-modal
    title="导出储值卡"
    :width="480"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="批次" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['batch', {rules: [{ required: true, message: '请输入批次' }]}]" allow-clear/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'

const fields = ['batch']
export default {
  name: 'ExportValueCardForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadEditInfo(this.model)
      }
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
    }
  }
}
</script>
